/* Based on https://github.com/jabes/snap-touch */
if(typeof a1ArticleSlider === 'undefined') {
    const SesoSlider = class {

        constructor(selector) {
            this.el = {};
            this.el.container = selector instanceof HTMLElement
                ? selector
                : (
                    document.getElementById(selector) ||
                    document.querySelector(selector)
                );
            this.el.slider = this.el.container ? this.el.container
                : undefined;
            this.el.animator = this.el.container ? this.el.container.querySelector('.animator')
                : undefined;
            this.el.slides = this.el.animator ? this.el.animator.querySelectorAll('.item')
                : [];
            this.el.anchors = this.el.animator ? this.el.animator.querySelectorAll('a[href]')
                : [];
            this.el.buttonNext = this.el.container ? this.el.container.querySelector('button.next')
                : undefined;
            this.el.buttonPrev = this.el.container ? this.el.container.querySelector('button.prev')
                : undefined;
            this.el.scrollIndicator = this.el.container ? this.el.container.querySelector('.scroll-indicator .indicator')
                : undefined;

            this.events = {};

            this.params = {
                sliderVisibleWidth: undefined,
                slideWidth: undefined,
                slideTotal: undefined,
                position: 0,
                positionMax: undefined,
                trackingStartX: undefined,
                trackingEndX: undefined,
                trackingDirection: undefined,
                translateX: 0,
                posX: 0,
                lastPosX: 0,
                amplitude: undefined,
                velocity: undefined,
                firstTimestamp: undefined,
                lastTimestamp: undefined,
                ticker: undefined,
                isClick: undefined,
                hasMoved: undefined,
                isEasing: undefined,
                isInitialized: undefined,
                noSliderClass: 'no-slider',
                sliderInitializedClass: 'slider-initialized'
            };

            if (this.el.container && this.el.slides.length >= 0) {
                this.bindEvents();
                // reset on init
                this.destroy();
                this.resize();
            }
        }

        preventDefault(event) {
            event.preventDefault();
            return false;
        }

        dispatchEvent(typeArg, customEventInit = { bubbles: false, cancelable: false, detail: null }) {
            const event = new window.CustomEvent(typeArg, customEventInit);
            this.el.container.dispatchEvent(event);
            return event;
        }

        bindEvents() {
            this.on(this.el.animator, 'dragstart', this.preventDefault);
            this.on(this.el.animator, 'touchstart', this.touchStart);
            this.on(this.el.animator, 'mousedown', this.touchStart);
            this.on(this.el.animator, 'touchend', this.touchEnd);
            //this.on(this.el.animator, 'mouseup', this.stopTracking);

            // Add event listeners to buttons (next/prev)
            this.on(this.el.buttonNext, 'click', this.goToNext);
            this.on(this.el.buttonPrev, 'click', this.goToPrev);

            // Add event listener for keydown (arrow left/right moves)
            // this.on(this.el.container.closest('.a1-slider-product'), 'keydown', this.keyboardMove);

            var _this = this;
            for (var i = 0; i < this.el.anchors.length; i++) {
                this.on(this.el.anchors.item(i), 'click', function (event) {
                    _this.preventDefault(event);
                    if (_this.params.isClick) {
                        _this.delayLocationChange(event._target.href);
                    }
                });
            }

            this.on(window, 'resize', this.resize);
        }

        goToNext(event) {
            event.preventDefault();
            this.setPosition(this.params.position + 1);
        }

        goToPrev(event) {
            event.preventDefault();
            this.setPosition(this.params.position - 1);
        }

        /*
        keyboardMove(event) {
            if (event.code == 'ArrowRight' && this.params.position < this.params.positionMax) {
                this.goToNext(event);
            } else if (event.code == 'ArrowLeft' && this.params.position > 0) {
                this.goToPrev(event);
            }
        }
        */

        on(target, type, callback) {
            const key = target._key || Math.random().toString(36).substring(7);
            target._key = key;
            if (!this.events[key]) {
                this.events[key] = {};
            }
            if (!this.events[key][type]) {
                this.events[key][type] = {
                    target: target,
                    listeners: [],
                };
            }
            const _listener = (event) => {
                event._key = key;
                event._target = target;
                callback.call(this, event);
            };
            this.events[key][type].listeners.push(_listener);
            target.addEventListener(type, _listener);
        }

        off(target, type = null) {
            const key = target._key;
            const _removeByType = (key, type) => {
                const target = this.events[key][type].target;
                const listeners = this.events[key][type].listeners;
                for (let i = 0; i < listeners.length; i++) {
                    target.removeEventListener(type, listeners[i]);
                    this.events[key][type].listeners.splice(i, 1);
                }
                if (this.events[key][type].listeners.length === 0) {
                    delete this.events[key][type];
                }
                if (Object.keys(this.events[key]).length === 0) {
                    delete this.events[key];
                }
            };
            if (this.events[key]) {
                if (type) {
                    _removeByType(key, type);
                } else {
                    const events = this.events[key];
                    for (let type in events) {
                        if (events[type]) {
                            _removeByType(key, type);
                        }
                    }
                }
            }
        }

        startTracking(event) {
            if (this.params.ticker) {
                this.stopTracking();
            }
            this.params.ticker = window.setInterval(this.track.bind(this), 100);

            this.params.trackingStartX = this.eventCoords(event, 'startTracking').x;
            this.params.translateX = this.translateValues(this.el.animator).x;

            this.on(document, 'touchmove', this.touchMove);
            this.on(document, 'mousemove', this.touchMove);
            this.on(document, 'touchend', this.touchEnd);
            this.on(document, 'mouseup', this.touchEnd);
            this.dispatchEvent('SesoSlider.trackingStart');
        }

        stopTracking() {
            window.clearInterval(this.params.ticker);
            delete this.params.ticker;

            this.off(document);
            this.dispatchEvent('SesoSlider.trackingEnd');
        }

        track() {
            var now = Date.now();
            var timeElapsed = now - this.params.lastTimestamp;
            var delta = this.params.posX - this.params.lastPosX;
            var v = 1000 * delta / (1 + timeElapsed);
            this.params.velocity = 0.8 * (v + 0.2) * this.params.velocity;
            this.dispatchEvent('SesoSlider.tracking', {
                bubbles: false,
                cancelable: false,
                detail: {
                    now: now,
                    delta: delta,
                    velocity: this.params.velocity,
                    posX: this.params.posX,
                    lastPosX: this.params.lastPosX,
                    lastTimestamp: this.params.lastTimestamp
                }
            });
            this.params.lastPosX = this.params.posX;
            this.params.lastTimestamp = now;
        }

        touchStart(event) {
            this.params.velocity = 0;
            this.params.amplitude = 0;
            this.params.posX = this.params.posX || 0;
            this.params.lastPosX = this.params.posX;
            this.params.firstTimestamp = Date.now();
            this.params.lastTimestamp = this.params.firstTimestamp;
            this.params.trackingStartX = this.eventCoords(event, 'touchStart').x;
            this.params.isEasing = false;
            this.params.isClick = false;
            this.params.hasMoved = false;
            this.startTracking(event);
        }

        touchMove(event) {
            if (this.params.isInitialized) {
                const delta = this.eventCoords(event, 'toucheMove').x - this.params.trackingStartX;
                this.params.trackingDirection = (delta >= 0) ? 'right' : 'left';

                // move left/right
                if (delta > 20 || delta < -20) {
                    // check left and right border
                    let moveTo = delta + this.params.translateX;
                    if (moveTo > 0) {
                        moveTo = 0;
                    }
                    const rightBorder = (this.params.trackingEndX - this.params.sliderVisibleWidth) * -1;
                    if (moveTo < rightBorder) {
                        moveTo = rightBorder;
                    }

                    // move slider left/right
                    this.el.animator.style.transform = 'translate(' + moveTo + 'px, 0)';
                }
            }
        }

        touchEnd(event) {
            this.stopTracking();
            this.params.amplitude = this.params.targetX - this.params.posX;
            this.params.lastTimestamp = Date.now();

            if (this.params.isInitialized) {
                this.requestAnimation(this.snapPosition);
            }
            // IE and FF fire click events on drag/swipe, so we need to decide if click behaviour should be honoured
            var timeElapsed = this.params.lastTimestamp - this.params.firstTimestamp;
            this.params.isClick = !this.params.hasMoved && timeElapsed < 300;
        }

        snapPosition() {
            var timeConstant = 325;
            var timeElapsed = Date.now() - this.params.lastTimestamp;
            var delta = -this.params.amplitude * Math.exp(-timeElapsed / timeConstant);
            var xMin = this.params.slideWidth * -(this.params.slideTotal - 1);
            var xMax = 0;
            this.params.isEasing = this.params.posX > xMin && this.params.posX < xMax && (delta > 20 || delta < -20);

            const snap = this.params.slideWidth;
            const myX = this.translateValues(this.el.animator).x * -1;
            let position = 0;
            if (this.params.trackingDirection == 'left') {
                position = (Math.ceil(myX / snap) * snap) / this.params.slideWidth;
            } else {
                position = (Math.floor(myX / snap) * snap) / this.params.slideWidth;
            }
            this.setPosition(position, 'snapPosition');
        }

        requestAnimation(callback) {
            callback = callback.bind(this);
            if (window.requestAnimationFrame) {
                window.requestAnimationFrame(callback);
            } else {
                window.setTimeout(callback, 10);
            }
        }

        delayLocationChange(href) {
            var setLocation = function setLocation(href) {
                if (this.params.isEasing) {
                    this.delayLocationChange(href);
                } else {
                    window.location.href = href;
                }
            };
            window.setTimeout(setLocation.bind(this, href), 50);
        }

        translateValues(element) {
            const style = window.getComputedStyle(element);
            const matrix = style['transform'] || style.webkitTransform || style.mozTransform

            // No transform property. Simply return 0 values.
            if (matrix === 'none' || typeof matrix === 'undefined') {
                return {
                    x: 0,
                    y: 0,
                    z: 0
                }
            }

            // Can either be 2d or 3d transform
            const matrixType = matrix.includes('3d') ? '3d' : '2d'
            const matrixValues = matrix.match(/matrix.*\((.+)\)/)[1].split(', ')

            // 2d matrices have 6 values
            // Last 2 values are X and Y.
            // 2d matrices does not have Z value.
            if (matrixType === '2d') {
                return {
                    x: parseInt(matrixValues[4]),
                    y: parseInt(matrixValues[5]),
                    z: 0
                }
            }

            // 3d matrices have 16 values
            // The 13th, 14th, and 15th values are X, Y, and Z
            if (matrixType === '3d') {
                return {
                    x: parseInt(matrixValues[12]),
                    y: parseInt(matrixValues[13]),
                    z: parseInt(matrixValues[14])
                }
            }

            return {
                x: 0,
                y: 0,
                z: 0
            }
        }

        eventCoords(event) {
            const touchEvents = ['touchmove', 'touchstart', 'touchend'];
            const isTouchEvent = touchEvents.indexOf(event.type) > -1;
            if (isTouchEvent) {
                let touch = event.targetTouches[0] || event.changedTouches[0];
                return {
                    x: touch.clientX,
                    y: touch.clientY,
                };
            } else {
                return {
                    x: event.clientX,
                    y: event.clientY,
                };
            }
        }

        setPosition(position) {
            if (position > this.params.positionMax) {
                this.params.position = this.params.positionMax;
            } else {
                this.params.position = (position < 0) ? 0 : position;
            }

            const xTarget = this.params.position * this.params.slideWidth * -1;
            this.el.animator.style.transform = 'translate(' + xTarget + 'px, 0)';
            this.params.hasMoved = true;

            // Take care of buttons
            if (position == 0) {
                this.el.buttonPrev.setAttribute('disabled', 'disabled');
            } else {
                this.el.buttonPrev.removeAttribute('disabled');
            }
            if (position == this.params.positionMax) {
                this.el.buttonNext.setAttribute('disabled', 'disabled');
            } else {
                this.el.buttonNext.removeAttribute('disabled');
            }

            // Take care of scroll indicator
            this.el.scrollIndicator.style.left = position * (100 / (this.params.positionMax + 1)) + '%';

            this.dispatchEvent('SesoSlider.positionChanged', {
                bubbles: false,
                cancelable: false,
                detail: {
                    posX: this.params.posX
                }
            });
        }

        resize() {
            this.params.sliderVisibleWidth = this.el.slider.getBoundingClientRect().width;
            this.params.slideWidth = this.el.slides.item(0).getBoundingClientRect().width;
            this.params.slideTotal = this.el.slides.length;
            this.params.slideVisibleTotal = Math.floor(this.el.animator.getBoundingClientRect().width / this.params.slideWidth);
            this.params.positionMax = this.params.slideTotal - this.params.slideVisibleTotal;
            this.params.trackingEndX = this.params.slideWidth * this.params.slideTotal;

            let sliderNeeded = this.params.sliderVisibleWidth > (this.params.slideWidth * this.params.slideTotal) ? true : false;

            // check if slider is needed
            if (sliderNeeded && this.params.isInitialized) {
                // if all slides are visible and slider was created -> destroy slider
                this.destroy();
            } else if (!sliderNeeded && !this.params.isInitialized) {
                // if not all slides are visible and slider is not created -> create
                this.create();
            } else if (this.params.isInitialized) {
                // if not all slides are visible and slider is created -> reposition
                this.setPosition(this.params.position, 'resize');
            }

            // Set scroll indicator width
            this.el.scrollIndicator.style.width = 100 / (this.params.positionMax + 1) + '%';

            this.dispatchEvent('SesoSlider.resized', {
                bubbles: false,
                cancelable: false,
                detail: {
                    slideWidth: this.params.slideWidth,
                    slideTotal: this.params.slideTotal,
                }
            });
        }

        create() {
            // show controls
            this.el.container.classList.remove(this.params.noSliderClass);

            // set slider position
            this.setPosition(this.params.position, 'resize');

            // initialze
            this.params.isInitialized = true;
            this.el.slider.classList.add(this.params.sliderInitializedClass);
            this.dispatchEvent('SesoSlider.created');
        }

        destroy() {
            // hide controls
            this.el.container.classList.add(this.params.noSliderClass);

            // reset slider position
            this.el.animator.style.transform = 'translate(0, 0)';

            // destroy
            this.params.isInitialized = false;
            this.el.slider.classList.remove(this.params.sliderInitializedClass);
            this.dispatchEvent('SesoSlider.destroyed');
        }
    }

    if (typeof window !== 'undefined') {
        window.a1ArticleSlider = SesoSlider;
    }

    window.addEventListener('load', (event) => {
        // slider article
        document.querySelectorAll('a1-fragment[name="slider-article"]').forEach(function (fragment) {
            window.a1ArticleSlider = new SesoSlider(fragment.querySelector('.slider'));
        });
    });
}
